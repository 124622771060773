<template>
  <div class="freeuse">
    <div class="main">
      <div class="free_btn" @click="saoma">扫码</div>

      <div class="free_btn" @click="saoma1">扫码解绑</div>

      <div class="free_btn" @click="saoma3" v-if="showSaomaBind">扫码绑定</div>

      <div class="free_btn" @click="saoma4" v-if="showSaomaYanfa">扫描IMEI（研发）</div>

      <!-- <div class="zhuyi">注：ios（苹果）用户请点击右上方三个点(...)后点击刷新再进行操作</div> -->
      <!-- <div class="free_btn" @click="saoma2">设备UUID解绑</div> -->
      <!-- <van-dialog v-model="show2" title="设备解绑" show-cancel-button confirmButtonColor="#0070fb" @confirm="jiebang2">
        <van-field v-model="UUID" label="UUID" placeholder="设备UUID解绑" />
        <van-field v-model="password" type="password" label="解绑密码" placeholder="请输入密码" />
      </van-dialog> -->
      <van-dialog v-model="show" title="设备解绑" show-cancel-button confirmButtonColor="#0070fb" @confirm="jiebang">
        <van-field v-model="deviceSn" label="租借二维码" placeholder="" aria-disabled="false" />
        <van-field v-model="password" type="password" label="解绑密码" placeholder="请输入密码" />
      </van-dialog>

      <van-dialog v-model="show3" title="设备绑定" show-cancel-button confirmButtonColor="#0070fb" @confirm="deviceBind">
        <van-button plain type="info" size="mini" style="margin-left: 20px;" @click="saoDevice()">扫描设备码</van-button>
        <van-field v-model="deviceSn" label="租借二维码" placeholder="" aria-disabled="false" />
        <van-field v-model="deviceName" label="imei/deviceName" placeholder="请扫描设备码获取" />
      </van-dialog>
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
export default {
  data() {
    return {
      mobile: "",
      password: "",
      deviceName: '',
      deviceSn: '',
      show: false,
      show2: false,
      show3: false,
      UUID: "",
      canrequest: true,
      showSaomaBind: false,
      showSaomaYanfa: false
    };
  },
  mounted() {
    var mobile = localStorage.getItem('loginMobile')
    if (mobile && this.$http.beishiTeMobileArr) {
      if (this.$http.beishiTeMobileArr.indexOf(mobile) != -1) {
        this.showSaomaBind = true
        this.$http.changeUrl2()
        this.$http.checkB()
      } else {
        this.$http.check();
      }
    }

    if (mobile && this.$http.beishiTeMobileArr2) {
      if (this.$http.beishiTeMobileArr2.indexOf(mobile) != -1) {
        this.showSaomaYanfa = true
      }
    }

  },
  methods: {
    //检测手机号
    phonejc() {
      console.log(this.mobile);
      if (/^1[3-9]\d{9}$/.test(this.mobile)) {
        localStorage.setItem("mobile", this.mobile);
      } else {
        this.mobile = "";
        Notify({ type: "primary", message: "手机号格式错误" });
      }
    },
    saoma() {
      // let str = '1/HQTF2401000001';
      // let deviceSnarr = str.split("/");
      // let deviceSn = deviceSnarr[deviceSnarr.length - 1];
      // localStorage.setItem("deviceSn", deviceSn);
      // this.$router.push("/shebei");
      // return
      if (this.canrequest) {
        this.canrequest = false
        this.$http.wechatsign({ url: window.location.href }).then((res) => {
          console.log(window);
          console.log(JSON.stringify(res));
          setTimeout(() => {
            this.canrequest = true
          }, 3000)
          window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            //                                debug : true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.appId, // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.nonceStr, // 必填，生成签名的随机串
            signature: res.signature, // 必填，签名，见附录1
            jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          window.wx.ready(() => {
            window.wx.scanQRCode({
              needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
              scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
              success: (res) => {
                console.log("成功扫码", res);
                this.canrequest = true
                if (res.resultStr) {
                  let str = res.resultStr;
                  let deviceSnarr = str.split("/");
                  let deviceSn = deviceSnarr[deviceSnarr.length - 1];
                  if (this.showSaomaBind && deviceSn && !deviceSn.startsWith('HQTF')) {
                    alert('请扫描租借二维码')
                    return
                  }
                  localStorage.setItem("deviceSn", deviceSn);
                  this.$router.push("/shebei");
                }
              },
              error: function (res) {
                this.canrequest = true
                alert("失败", res);
              },
            });
          });
        });
      }
    },
    saoma1() {
      // let str = '1/HQTF2403000005';
      // let deviceSnarr = str.split("/");
      // let deviceSn = deviceSnarr[deviceSnarr.length - 1];
      // if (this.showSaomaBind && deviceSn && !deviceSn.startsWith('HQTF')){
      //   alert('请扫描租借二维码')
      //   return
      // }
      // localStorage.setItem("deviceSn", deviceSn);
      // // this.$router.push("/shebei");
      // this.show = true;
      // this.deviceSn = deviceSn
      // return
      if (this.canrequest) {
        this.canrequest = false
        this.$http.wechatsign({ url: window.location.href }).then((res) => {
          console.log(window);
          console.log(JSON.stringify(res));
          setTimeout(() => {
            this.canrequest = true
          }, 3000)
          window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            //                                debug : true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.appId, // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.nonceStr, // 必填，生成签名的随机串
            signature: res.signature, // 必填，签名，见附录1
            jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          window.wx.ready(() => {
            window.wx.scanQRCode({
              needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
              scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
              success: (res) => {
                console.log("成功扫码", res);
                this.canrequest = true
                if (res.resultStr) {
                  let str = res.resultStr;
                  let deviceSnarr = str.split("/");
                  let deviceSn = deviceSnarr[deviceSnarr.length - 1];
                  if (this.showSaomaBind && deviceSn && !deviceSn.startsWith('HQTF')) {
                    alert('请扫描租借二维码')
                    return
                  }
                  if (deviceSn) {
                    localStorage.setItem("deviceSn", deviceSn);
                    // this.$router.push("/shebei");
                    this.show = true;
                    this.deviceSn = deviceSn
                  }

                }
              },
              error: function (res) {
                this.canrequest = true
                alert("失败", res);
              },
            });
          });
        });
      }

    },
    //绑定
    saoma3() {
      // let str = '1/HQTF2403000005';
      // let deviceSnarr = str.split("/");
      // let deviceSn = deviceSnarr[deviceSnarr.length - 1];
      // if (this.showSaomaBind && deviceSn && !deviceSn.startsWith('HQTF')) {
      //   alert('请扫描租借二维码')
      //   return
      // }
      // localStorage.setItem("deviceSn", deviceSn);
      // this.deviceSn = deviceSn
      // this.show3 = true;
      // return
      if (this.canrequest) {
        this.canrequest = false
        this.$http.wechatsign({ url: window.location.href }).then((res) => {
          console.log(window);
          console.log(JSON.stringify(res));
          setTimeout(() => {
            this.canrequest = true
          }, 3000)
          window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            //                                debug : true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.appId, // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.nonceStr, // 必填，生成签名的随机串
            signature: res.signature, // 必填，签名，见附录1
            jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          window.wx.ready(() => {
            window.wx.scanQRCode({
              needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
              scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
              success: (res) => {
                console.log("成功扫码", res);
                this.canrequest = true
                if (res.resultStr) {
                  let str = res.resultStr;
                  let deviceSnarr = str.split("/");
                  let deviceSn = deviceSnarr[deviceSnarr.length - 1];
                  if (this.showSaomaBind && deviceSn && !deviceSn.startsWith('HQTF')) {
                    alert('请扫描租借二维码')
                    return
                  }
                  localStorage.setItem("deviceSn", deviceSn);
                  this.deviceSn = deviceSn
                  this.show3 = true;
                }
              },
              error: function (res) {
                this.canrequest = true
                alert("失败", res);
              },
            });
          });
        });
      }

    },
    saoma4() {
      // let deviceSn = '865308068408849';
      // localStorage.setItem("deviceName", deviceSn);
      // this.$router.push("/abstshebei");
      // return
      if (this.canrequest) {
        this.canrequest = false
        this.$http.wechatsign({ url: window.location.href }).then((res) => {
          console.log(window);
          console.log(JSON.stringify(res));
          setTimeout(() => {
            this.canrequest = true
          }, 3000)
          window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            //                                debug : true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.appId, // 必填，公众号的唯一标识
            timestamp: res.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.nonceStr, // 必填，生成签名的随机串
            signature: res.signature, // 必填，签名，见附录1
            jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          window.wx.ready(() => {
            window.wx.scanQRCode({
              needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
              scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
              success: (res) => {
                console.log("成功扫码", res);
                this.canrequest = true
                if (res.resultStr) {
                  let str = res.resultStr;
                  let deviceSnarr = str.split(";");
                  let deviceSn = deviceSnarr[0];
                  localStorage.setItem("deviceName", deviceSn);
                  this.$router.push("/abstshebei");

                }
              },
              error: function (res) {
                this.canrequest = true
                alert("失败", res);
              },
            });
          });
        });
      }

    },
    deviceBind() {
      if (!this.deviceName) {
        alert("请输入 imei/deviceName");
      } else {
        this.$http
          .bindDevice({
            deviceName: this.deviceName,
            deviceSn: localStorage.getItem("deviceSn"),
          })
          .then((res) => {
            alert(res.msg);
            this.deviceName = "";
          });
      }

    },
    jiebang() {
      if (!this.password) {
        alert("请输入密码");
      } else {
        this.$http
          .unbindDevice({
            password: this.password,
            deviceSn: localStorage.getItem("deviceSn"),
          })
          .then((res) => {
            alert(res.msg);
            this.password = "";
          });
      }
    },
    saoma2() {
      this.show2 = true;
    },
    jiebang2() {
      console.log(this.UUID, this.password);
    },
    saoDevice() {
      // let deviceSn = '865308068408849';
      // this.deviceName = deviceSn;
      // return
      this.$http.wechatsign({ url: window.location.href }).then((res) => {
        console.log(window);
        console.log(JSON.stringify(res));
        setTimeout(() => {
          this.canrequest = true
        }, 3000)
        window.wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          //                                debug : true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名，见附录1
          jsApiList: ["scanQRCode"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        window.wx.ready(() => {
          window.wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: (res) => {
              console.log("成功扫码", res);
              this.canrequest = true
              if (res.resultStr) {
                let str = res.resultStr;
                let deviceSnarr = str.split(";");
                this.deviceName = deviceSnarr[0]
              }
            },
            error: function (res) {
              this.canrequest = true
              alert("失败", res);
            },
          });
        });
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.freeuse {
  width: 100%;
  height: 100%;
  background: #f8f8f8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .free_input {
    margin-top: 8px;
    width: 336px;
    height: 54px;
    border-bottom: 1px solid #d6dde5;
    display: flex;
    align-items: center;
    margin-bottom: 419px;

    .input_model {
      color: #2d3538;
      font-size: 16px;
    }

    .input_box {
      flex: 1;
      height: 100%;
      margin-bottom: 2px;

      .input_man {
        font-size: 14px;
        width: 100%;
        height: 100%;
        background: #f8f8f8;
      }
    }
  }

  .free_btn {
    width: 275px;
    height: 40px;
    background: linear-gradient(135deg, #35c3ff 0%, #1890ff 100%);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    box-shadow: 0 0 0 gray, 0px 0px 0px gray, 0 2px 1px #35c3ff,
      0px 0px 0px gray;
    /* margin-bottom: 202px; */
    margin: 0 auto;
    margin-top: 50px;
  }
}
</style>